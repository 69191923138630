<script>
export default {
    name: "council-edit",

    props: ['council', 'agreements', 'domain', 'inspection_id'],

    data() {
        return {
            errors: [],
            councilData: {},
            councilUpdate: {},
            agreementsData: [],
            showSpinner: false,
            authorized: false,
            connected: false,
            activated: false
        }
    },

    mounted() {
        this.councilData = JSON.parse(this.council);
        this.authorized = Boolean(this.councilData.iasset_requested);
        this.activated = (this.councilData.iasset_council || this.councilData.iasset_council != undefined) ? Boolean(this.councilData.iasset_council.is_activated) : false;
        this.connected = (this.councilData.iasset_council || this.councilData.iasset_council != undefined) ? Boolean(this.councilData.iasset_council.is_connected) : false;
        this.councilUpdate = this.councilData;
        this.agreementsData = JSON.parse(this.agreements);
        this.councilUpdate.iassetDomain = this.domain;
        this.councilUpdate.inspectionId = this.inspection_id;
    },

    methods: {
        update() {
            this.errors = [];

            axios.patch('/admin/councils/' + this.councilUpdate.id, {
                data: {
                    id: this.councilUpdate.id,
                    email: this.councilUpdate.email,
                    website: this.councilUpdate.website,
                    agreement_id: this.councilUpdate.agreement_id,
                    email_optout: this.councilUpdate.email_optout,
                }
            }).then(res => {
                if (res.status === 200) {
                    sessionStorage.setItem("alert_type", "success");
                    sessionStorage.setItem("message", this.$gettext("Council has been saved successfully"));

                    this.$root.$emit("show-flash-message");
                }
            }).catch(error => {
                this.errors = error.response.data.errors;
            })
        },

        userAuthorize() {
            this.showSpinner = true;
            this.errors = [];

            axios.post('/admin/authorize-user-iasset', {
                council_name: this.councilData.name,
                council_id: this.councilData.id
            }).then(() => {
                this.$root.$emit('modal-message-ajax', {
                    title: 'Success',
                    body: 'An email has been sent to the iASSET team.',
                });
            }).catch(error => {
                this.errors = error.response.data.errors;
            }).finally( () => {
                this.showSpinner = false;
                this.authorized = true;
            });
        },

        generalInspectionAuthorize() {
            this.showSpinner = true;
            this.errors = [];

            axios.post('/admin/general-inspection-authorize-iasset', {
                domain: this.councilUpdate.iassetDomain,
                council_id: this.councilData.id,
            }).then((response) => {
                this.councilUpdate.inspectionId = response.data.inspection_id;

                this.$root.$emit('modal-message-ajax', {
                    title: 'Success',
                    body: 'An email has been sent to the iASSET team.',
                });

                window.setTimeout(() => {
                    window.location.reload();
                }, 3000)
            }).catch(error => {
                this.errors = error.response.data.message;
            }).finally( () => {
                this.showSpinner = false;
                this.connected = true;
            });

            this.update();
        },

        activateConnection() {
            axios.post('/admin/activate-iasset-connection', {
                council_id: this.councilData.id,
            }).then(() => {
                this.$root.$emit('modal-message-ajax', {
                    title: 'Success',
                    body: 'Connection between VDB and iAsset is activated.',
                });
            }).catch(error => {
                this.errors = error.response.data.errors;
            }).finally(() => {
                this.showSpinner = false;
                this.activated = true;
            });
        }
    }
}
</script>

<template>
    <div class="modal fade" tabindex="-1" id="exportReportsModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $gettext('Export Reports') }}</h5>

                    <!--begin::Close-->
                    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                         data-bs-dismiss="modal" aria-label="Close">
                        <span class="svg-icon svg-icon-2x"></span>
                    </div>
                    <!--end::Close-->
                </div>

                <div class="modal-body">
                    <div class="form-group">
                        <label class="col-form-label" v-text="$gettext('Statuses') + ':'"></label>
                        <select class="form-control w-100" id="status" name="status" v-model="filtersExport.status">
                            <option value="">{{ $gettext('All statuses') }}</option>
                            <option v-for="(status, index) in statuses" :key="index"
                                    v-text="$gettext(status)" :value="index"></option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label class="col-form-label" v-text="$gettext('Categories') + ':'"></label>
                        <v-autocomplete dense auto-select-first clearable filled solo ref="category"
                                        hide-selected v-model="filtersExport.category" :items="categories"
                                        color="white" item-text="name" item-value="id" v-if="reportType === 'problem'"
                                        class="form-control w-100" :placeholder="$gettext('All categories')"
                                        @change="hideMenu('category');" @input="showMenu('category');"
                                        @mouseup="showMenu('category')" @click:append-outer="toggleMenu('category')">
                        </v-autocomplete>
                    </div>

                    <div class="form-group mb-4">
                        <div>
                            <label for="start_date" class="col-form-label" v-text="$gettext('Start date') + ':'"></label>
                            <input class="form-control" type="date" id="start_date" name="start_date" v-model="filtersExport.startDate">
                        </div>

                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert" v-if="this.error">
                            <strong v-text="$gettext('The end date cannot be earlier than the start date')"></strong>
                        </span>

                        <div>
                            <label for="end_date" class="col-form-label" v-text="$gettext('End date') + ':'"></label>
                            <input class="form-control" type="date" id="end_date" name="end_date" v-model="filtersExport.endDate">
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-2 mb-4">
                        <span class="me-2" v-text="$gettext('Show deleted?')"></span>
                         <label class="switch" for="with_trashed">
                             <input type="checkbox" v-model="filtersExport.withTrashed"
                                   name="with_trashed" id="with_trashed">
                             <span class="slider round"></span>
                          </label>
                    </div>
                    <div class="d-flex align-items-center me-2">
                        <span class="me-2" v-text="$gettext('Show rating?')"></span>
                         <label class="switch" for="show_rating">
                             <input type="checkbox" v-model="filtersExport.showRating"
                                   name="show_rating" id="show_rating">
                             <span class="slider round"></span>
                          </label>
                    </div>
                    <div class="form-group mt-5">
                        <button class="btn btn-light" @click="exportToFile('excel')" v-text="$gettext('Export Excel')"></button>
                        <button class="btn btn-light" @click="exportToFile('csv')" v-text="$gettext('Export CSV')"></button>
                    </div>
                </div>


                <div class="modal-footer d-flex justify-content-end">
                    <button type="button" class="btn admin-create-button" data-bs-dismiss="modal">
                        {{ $gettext('Close') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">

import AutocompleteMixin from "../../mixins/autocomplete";

export default {
    name: 'export-reports-modal',

    props: [
        'statuses',
        'categories',
        'withTrashedReports'
    ],

    mixins: [
        AutocompleteMixin
    ],

    data() {
        return {
            users: [],
            selectedUser: '',
            filtersExport: {
                status: '',
                category: {},
                startDate: '',
                endDate: '',
                withTrashed: false,
                showRating: false,
            },
            exportType: 'excel',
            reportType: '',
            errors: [],
            error: false,
        };
    },

    methods: {
        exportToFile(type) {
            this.exportType = type;

            this.getReports();
        },

        getReports(url = '/admin/reports-export', options = this.filtersExport) {
            let self = this;

            self.error = false;

            axios.get(url, {
                params: {
                    category: this.filtersExport.category,
                    status: this.filtersExport.status,
                    startDate: this.filtersExport.startDate,
                    endDate: this.filtersExport.endDate,
                    reportType: this.reportType,
                    exportType: this.exportType,
                    withTrashed:this.filtersExport.withTrashed,
                    showRating:this.filtersExport.showRating,
                },
                responseType: "arraybuffer",
            }).then(response => {
                const fileName = (this.exportType == 'excel') ? 'reports.xlsx' : 'reports.csv';
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', fileName);

                document.body.appendChild(link);

                link.click();
            }).catch(errors => {
                self.error = true;
            });
        },
    },

    mounted() {
        self = this;

        this.reportType = (this.$root.isIdea()) ? 'idea' : 'problem';

        this.$root.$on('change-reports-filters', function(status=null, category=null, withTrashedReports=null) {
            self.filtersExport.status = status;
            self.filtersExport.category = category;
            self.filtersExport.withTrashed = withTrashedReports;
        });
    },
};
</script>
<style>
    div.modal-content {
        width: 600px;
    }
</style>
